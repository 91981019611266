import { Injectable } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class IconsRegistryService {

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) { }

  registerIcons() {
    this.matIconRegistry
      .addSvgIcon('store_1',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/app-store.svg'))
      .addSvgIcon('store_2',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/google-play.svg'))
      .addSvgIcon('store_3',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/google-play.svg'))
      .addSvgIcon('stadia_controller',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/stadia_controller.svg'));
  }
}

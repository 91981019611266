import {ErrorHandler, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SentryErrorHandler} from '@sentry/angular-ivy';

@Injectable()
export class GlobalErrorHandlerService extends SentryErrorHandler implements ErrorHandler {

  constructor(private readonly snackBar: MatSnackBar) {
    super();
  }

  handleError(error: any): void {
    const chunkFailedMessage = 'Failed to fetch dynamically imported module';

    super.handleError(error);
    if (error.message.startsWith(chunkFailedMessage)) {
      this.showAppUpdatedSnackBar();
    }
  }

  private showAppUpdatedSnackBar() {
    this.snackBar.open('Application is updated. Please, reload page to apply updates', 'Reload', {duration: 5000})
      .onAction()
      .subscribe(() => {
        window.location.reload();
      })
  }
}


import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import { iif, interval, Observable, of, Subject } from "rxjs";
import { distinctUntilChanged, map, startWith, switchMap } from "rxjs/operators";

type Theme = AppearanceOption.Dark | AppearanceOption.Light;

export enum AppearanceOption {
  Light = "light",
  Dark = "dark",
  Auto = "system"
}

const appearanceStorageKey = "appearance"

@Injectable({
  providedIn: 'root'
})
export class ColorSchemeService {
  private renderer: Renderer2;
  private appearanceOption: Subject<AppearanceOption> = new Subject<AppearanceOption>();
  get appearanceOption$(): Observable<AppearanceOption | null> {
    return this.appearanceOption.asObservable().pipe(startWith(this.getSavedAppearance()));
  }
  private colorSchemePrefix = 'color-scheme-';

  constructor(rendererFactory: RendererFactory2) {
    // Create new renderer from renderFactory, to make it possible to use renderer2 in a service
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  private getPrefersColorScheme(): Theme {
    // Detect if prefers-color-scheme is supported
    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      // Set colorScheme to Dark if prefers-color-scheme is dark. Otherwise, set it to Light.
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? AppearanceOption.Dark : AppearanceOption.Light;
    } else {
      // If the browser does not support prefers-color-scheme, set the default to dark.
      return AppearanceOption.Light;
    }
  }

  getSavedAppearance(): AppearanceOption | null {
    return window.localStorage.getItem(appearanceStorageKey) as AppearanceOption;
  }

  getTheme(): Observable<Theme> {
    return this.appearanceOption.pipe(
      switchMap((option) => iif<Theme, Theme>(
        () => option === AppearanceOption.Auto || option === null,
        interval(1000).pipe(startWith(0)).pipe(map(() => this.getPrefersColorScheme())),
        of(option as Theme)
      )),
    );
  }

  initialize() {
    this.getTheme().pipe(
      distinctUntilChanged()
    ).subscribe((newTheme) => {
      this.resetAppearance(this.invertTheme(newTheme));
      this.applyAppearance(newTheme);
    });
    this.updateAppearance(this.getSavedAppearance());
  }

  updateAppearance(option: AppearanceOption | null) {
    option = option || AppearanceOption.Auto;
    window.localStorage.setItem(appearanceStorageKey, option);
    this.appearanceOption.next(option);
  }

  applyAppearance(theme: Theme) {
    this.renderer.addClass(document.body, this.getBodyClass(theme));
  }

  private resetAppearance(theme: Theme) {
    this.renderer.removeClass(document.body, this.getBodyClass(theme));
  }

  private invertTheme(theme: Theme): Theme {
    switch (theme) {
      case AppearanceOption.Dark:
        return AppearanceOption.Light;
      case AppearanceOption.Light:
        return AppearanceOption.Dark;
    }
  }

  private getBodyClass(theme: Theme): string {
    return this.colorSchemePrefix + theme;
  }
}

import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from "./auth.service";
import {map, tap} from "rxjs/operators";

export const anonymousGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.isSignedIn().pipe(
    tap((isSignedIn) => {
      if (isSignedIn) {
        router.navigate([""]);
      }
    }),
    map(isSignedIn => !isSignedIn)
  )
}

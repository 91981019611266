import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from "./auth.service";
import {tap} from "rxjs/operators";

export const authGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.isSignedIn().pipe(
    tap((isSignedIn) => {
      if (!isSignedIn) {
        router.navigate(["auth", "sign-in"]);
      }
    })
  )

}

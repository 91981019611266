import {
  HttpRequest,
  HttpInterceptorFn,
  HttpHandlerFn,
} from '@angular/common/http';

export const contentTypeInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  let contentType: string | null = '';
  if (req.headers.has('Content-Type')) {
    contentType = req.headers.get('Content-Type');
  }

  req = req.clone({
    setHeaders: {
      'Content-Type': (contentType || 'application/json'),
    },
  });
  return next(req);
};

import { Component, OnInit } from '@angular/core';
import { ColorSchemeService } from "./core/color-scheme.service";
import { IconsRegistryService } from "./core/icons-registry.service";
import * as i18nIsoCountries from 'i18n-iso-countries';
import {RouterOutlet} from '@angular/router';
import {NgOptimizedImage} from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import langEn from 'i18n-iso-countries/langs/en.json'
import {SplashIconComponent} from './shared/splash-icon/splash-icon.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    MatMenuModule,
    NgOptimizedImage,
    SplashIconComponent,
  ],
})
export class AppComponent implements OnInit{
  isActivated = false;
  constructor(private colorSchemeService: ColorSchemeService,
              private iconsRegistryService: IconsRegistryService) {}

  ngOnInit(): void {
    this.iconsRegistryService.registerIcons();
    // fromPromise(import('i18n-iso-countries/langs/en.json')).pipe(first()).subscribe((langEn) => {
      i18nIsoCountries.registerLocale(langEn);
    // })

  }

  onActivate($event: any) {
    this.isActivated = true;
  }
}

import {authGuard} from './core/auth.guard';
import {anonymousGuard} from './core/anonymous.guard';
import {Routes} from '@angular/router';

export const appRoutes: Routes = [{
  path: 'demo',
  loadChildren: () => import('./demo/demo.routes')
}, {
  path: '',
  loadChildren: () => import('./home/home.routes'),
  canActivate: [authGuard],
}, {
  path: 'auth',
  loadChildren: () => import('./auth/auth.routes'),
  canActivate: [anonymousGuard],
}];
